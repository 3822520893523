import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { DocumentCardWhite } from '/features/pageOnly/LifeAtKLM/DocumentCard'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './FeaturedDocuments.css'

export function FeaturedDocuments({ documents, regularPage = undefined, layoutClassName = undefined }) {
  const isMounted = useRenderOnMount()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const initialSlide = 0
  const slidesPerView = isViewportMd ? 2 : 1
  const sliderDisabled = slidesPerView === documents.length || isViewportLg
  const showSliderButtons = !sliderDisabled && isMounted
  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: documents.length,
    slidesPerView,
    spacing: 16,
    initialSlide,
    sliderDisabled,
    origin: 0,
    loop: false,
    interactionTitle: 'featured-documents-slider'
  })

  return (
    <section data-x='featured-documents' className={cx(styles.component, regularPage && styles.regularPage, layoutClassName)}>
      <div className={styles.container}>
        <div ref={sliderRef} className={cx(styles.slider, 'keen-slider', sliderDisabled && styles.sliderDisabled)}>
          {documents.map((item, i) => (
            <div key={i + item._key} className={cx(styles.slideContainer, 'keen-slider__slide')}>
              <DocumentCardWhite layoutClassName={styles.cardLayout} {...{ item }} />
            </div>
          ))}
        </div>
        {showSliderButtons && <SliderButtons maxSlides={documents.length} {...{ currentSlide, slidesPerView, handleClick }} />}
      </div>
    </section>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}
