import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'

import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './DocumentCard.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function DocumentCardWhite({ item, layoutClassName = undefined }) {
  return <DocumentCardBase className={cx(styles.componentWhite, layoutClassName)} {...{ item }} />
}

export function DocumentCardLightBlue({ item, layoutClassName = undefined }) {
  return <DocumentCardBase className={cx(styles.componentLightBlue, layoutClassName)} {...{ item }} />
}

function DocumentCardBase({ item, className }) {
  const { title, image, description } = item || {}

  return (
    <div className={cx(styles.componentBase, className)}>
      <div className={styles.textContainer}>
        <CardHeading layoutClassName={styles.cardHeadingLayout} {...{ item, title }} />
        <p className={styles.cardDescription}>{description}</p>
      </div>
      {image && <CardImage layoutClassName={styles.imageLayout} {...{ image }} />}
    </div>
  )
}

function CardHeading({ item, title, layoutClassName = undefined }) {
  return (
    <h4 className={cx(styles.componentCardHeading, layoutClassName)}>
      <a href={determineDocumentPathSync({ document: item, routeMap })} data-x='link-in-featured-banner' className={styles.cardAnchor}>{title}</a>
    </h4>
  )
}

function CardImage({ image, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentCardImage, layoutClassName)}>
      <ImageOverlay layoutClassName={styles.imageOverlayLayout} />
      <ImageCover aspectRatio={2 / 1} {...{ image }} />
    </div>
  )
}

function ImageOverlay({ layoutClassName = undefined }) {
  return (
    <span className={cx(styles.componentImageOverlay, layoutClassName)}>
      <span className={styles.overlayIconContainer}>
        <Icon icon={arrowIcon} layoutClassName={styles.overlayIconLayout} />
      </span>
      <span className={styles.backgroundOverlay} />
    </span>
  )
}
