import { useKeenSlider } from 'keen-slider/react'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function useKeenSliderRefs({
  handleSlideChange,
  slidesNum,
  slidesPerView,
  spacing,
  initialSlide,
  sliderDisabled,
  origin,
  loop,
  interactionTitle,
  ...additionalOptions
}) {
  const [sliderRef, instanceRef] = useKeenSlider({
    disabled: Boolean(sliderDisabled),
    initial: initialSlide,
    loop,
    range: {
      align: true,
    },
    mode: 'free-snap',
    slides: {
      origin,
      number: slidesNum,
      spacing,
      perView: slidesPerView
    },
    slideChanged: (s) => {
      handleSlideChange(s.track.details.rel)
    },
    dragChecked(s) {
      trackInteraction({
        title: interactionTitle,
        action: 'dragged',
        type: 'slided',
        index: s.track.details.rel
      })
    },
    ...additionalOptions
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef]
  )

  return { sliderRef, instanceRef }
}
