import { Icon } from '/features/buildingBlocks/Icon'

import styles from './SliderButtons.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function SliderButtons({ handleClick, currentSlide, slidesPerView = 0, maxSlides = undefined, loop = undefined }) {
  return (
    <div className={styles.component}>
      <SliderButton
        onClick={() => handleClick(currentSlide - 1)}
        disabled={!loop && currentSlide === 0}
        ariaLabel='go to previous slide button'
        {...{ currentSlide }}
      />
      <SliderButton
        onClick={() => handleClick(currentSlide + 1)}
        disabled={!loop && currentSlide === maxSlides - slidesPerView}
        ariaLabel='go to next slide button'
        {...{ currentSlide }}
      />
    </div>
  )
}

function SliderButton({ onClick, disabled, ariaLabel }) {
  return (
    <button
      type='button'
      data-x='click-slider-button'
      className={styles.componentSliderButton}
      aria-label={ariaLabel}
      {...{ onClick, disabled }}
    >
      <span className={styles.buttonIconContainer}>
        <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}
